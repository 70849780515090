class Notify {
    //获取通知权限
    static notifyPermission(fromId: string, content: string) {
        const body = fromId + ":" + content
        // 先检查浏览器是否支持
        if (!('Notification' in window)) {
            console.log('This browser does not support desktop notification')
        }
        // 检查用户是否同意接受通知
        else if (Notification.permission === 'granted') {
            // console.log('granted')
            // If it's okay let's create a notification
            const notification = new Notification("客服新消息啦", {
                body: body,
                icon: "https://minio.ipay888.com/ipay/logo_ipay.png"
            });
        }
        // 否则我们需要向用户获取权限
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(function (permission) {
                // 如果用户接受权限，我们就可以发起一条消息
                if (permission === 'granted') {
                    console.log("granted")
                    const notification = new Notification("客服新消息", {
                        body: body,
                        icon: "https://minio.ipay888.com/ipay/logo_ipay.png"
                    });
                }
            })
        }else {
            console.log("notifyPermission：denied")
        }
        // 最后，如果执行到这里，说明用户已经拒绝对相关通知进行授权
        // 出于尊重，我们不应该再打扰他们了
    }

}

export default Notify;




